import React, { useState } from "react";
import { FaTimesCircle } from "react-icons/fa";
import imagePopup from "../../assets/banner-aviso.jpg";
import { Link } from "react-router-dom";
import * as S from "./styles";

import Off50 from "../../assets/50-off.png";

function PopUp() {
  const [open, setOpen] = useState(true);

  return (
    <S.Under
      href="javascript:void(0)"
      onClick={() => setOpen(false)}
      open={open}>
      <S.Container open={open}>
        <S.CloseButton onClick={() => setOpen(false)}>&times;</S.CloseButton>
        <Link to="/aviso-reserva">
          <img className="image" src={imagePopup} alt="popup" />
        </Link>
      </S.Container>
    </S.Under>
  );
}

export default PopUp;
